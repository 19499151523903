import React, { Component } from 'react';
import PutBodyParam from './putBodyParam';
import ShowBodyParams from './showBodyParams';
import ShowDiagram from './showDiagramAll';
import Service from '../../services/bodyParams';

export default class BodyParams extends Component {
  state = {
    bodyParams: [],
    gender: null,
    age: null,
  };

  componentDidMount() {
    this.updateData();
    this.fetchAdditionalInfo();
  }

  updateData = () => {
    Service.getAllBodyParams().then(
      (response) => {
        this.setState({
          bodyParams: response.data,
        });
      },
      (error) => {
        console.error("Error fetching body parameters:", error);
      }
    );
  };

  fetchAdditionalInfo = () => {
    Service.getGender().then(
      (response) => {
        this.setState({
          gender: response.data.gender,
        });
      },
      (error) => {
        console.error("Error fetching gender:", error);
      }
    );

    Service.getAge().then(
      (response) => {
        this.setState({
          age: response.data.age,
        });
      },
      (error) => {
        console.error("Error fetching age:", error);
      }
    );
  };

  render() {
    return (
      <div className="card">
        <ShowBodyParams
          bodyParams={this.state.bodyParams}
          gender={this.state.gender}
          age={this.state.age}
          updateData={this.updateData} // Pass updateData correctly
        />
        <PutBodyParam clientId={null} updateData={this.updateData} />
        <ShowDiagram bodyParams={this.state.bodyParams} />
      </div>
    );
  }
}
