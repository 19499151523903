import React, { Component } from "react";
import Collapsible from 'react-collapsible';
import ShowDiagram from "./showDiagramOne";
import ShowBmi from "./showBmi";
import ShowBmr from "./showBmr";
import ShowCpf from "./showCpf";
import Gender from "./gender";
import Age from "./age";
import { BodyParamsItem } from '../../types/types';
import { withTranslation, WithTranslation } from "react-i18next";

type Props = WithTranslation & {
  bodyParams: BodyParamsItem[];
  gender: string | null;
  age: number | null;
  updateData: () => void;
};

type State = {
  expandedItemIndex: number | null;
  bmiExpanded: boolean;
  genderExpanded: boolean;
  ageExpanded: boolean;
  bmrExpanded: boolean;
  cpfExpanded: boolean;
};

class ShowBodyParams extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      expandedItemIndex: null,
      bmiExpanded: false,
      genderExpanded: false,
      ageExpanded: false,
      bmrExpanded: false,
      cpfExpanded: false,
    };
  }

  handleItemClick(index: number) {
    this.setState((prevState) => ({
      expandedItemIndex: prevState.expandedItemIndex === index ? null : index,
    }));
  }

  handleBmiClick() {
    this.setState((prevState) => ({
      bmiExpanded: !prevState.bmiExpanded,
    }));
  }

  handleGenderClick() {
    this.setState((prevState) => ({
      genderExpanded: !prevState.genderExpanded,
    }));
  }

  handleAgeClick() {
    this.setState((prevState) => ({
      ageExpanded: !prevState.ageExpanded,
    }));
  }

  handleBmrClick() {
    this.setState((prevState) => ({
      bmrExpanded: !prevState.bmrExpanded,
    }));
  }

  handleCpfClick() {
    this.setState((prevState) => ({
      cpfExpanded: !prevState.cpfExpanded,
    }));
  }

  isItemExpanded(index: number): boolean {
    return this.state.expandedItemIndex === index;
  }

  getUniqueBodyParams = () => {
    const uniqueParams: { [key: string]: BodyParamsItem } = {};
    this.props.bodyParams.forEach((item) => {
      uniqueParams[item.dict_body_params_name] = item;
    });
    return Object.values(uniqueParams);
  };

  render() {
    const { bmrExpanded, bmiExpanded, genderExpanded, ageExpanded, cpfExpanded } = this.state;
    const { t, gender, age } = this.props;
    const uniqueBodyParams = this.getUniqueBodyParams();

    return (
      <div>
        <ul>
          <li>
            <Collapsible
              trigger={
                <div
                  style={{
                    cursor: 'pointer',
                    display: 'flex',
                    flexDirection: 'column',
                    marginBottom: '10px',
                  }}
                  onClick={() => this.handleGenderClick()}
                >
                  <span style={{ marginRight: '10px', width: '150px', textAlign: 'right' }}>
                    {t("body_params.gender")}: {gender ? gender : t("body_params.gender_not_set")}
                  </span>
                </div>
              }
              open={genderExpanded}
            >
              <Gender />
            </Collapsible>
          </li>
          <li>
            <Collapsible
              trigger={
                <div
                  style={{
                    cursor: 'pointer',
                    display: 'flex',
                    flexDirection: 'column',
                    marginBottom: '10px',
                  }}
                  onClick={() => this.handleAgeClick()}
                >
                  <span style={{ marginRight: '10px', width: '150px', textAlign: 'right' }}>
                    {t("body_params.age")}: {age !== null ? age : t("body_params.age_not_set")}
                  </span>
                </div>
              }
              open={ageExpanded}
            >
              <Age />
            </Collapsible>
          </li>
          <li>
            <Collapsible
              trigger={
                <div
                  style={{
                    cursor: 'pointer',
                    display: 'flex',
                    flexDirection: 'column',
                    marginBottom: '10px',
                  }}
                  onClick={() => this.handleBmiClick()}
                >
                  <span style={{ marginRight: '10px', width: '150px', textAlign: 'right' }}>
                    {t("body_params.body_mass_index")}
                  </span>
                </div>
              }
              open={bmiExpanded}
            >
              <ShowBmi />
            </Collapsible>
          </li>
          <li>
            <Collapsible
              trigger={
                <div
                  style={{
                    cursor: 'pointer',
                    display: 'flex',
                    flexDirection: 'column',
                    marginBottom: '10px',
                  }}
                  onClick={() => this.handleBmrClick()}
                >
                  <span style={{ marginRight: '10px', width: '150px', textAlign: 'right' }}>
                    {t("body_params.basal_metabolic_rate")}
                  </span>
                </div>
              }
              open={bmrExpanded}
            >
              <ShowBmr />
            </Collapsible>
          </li>
          <li>
            <Collapsible
              trigger={
                <div
                  style={{
                    cursor: 'pointer',
                    display: 'flex',
                    flexDirection: 'column',
                    marginBottom: '10px',
                  }}
                  onClick={() => this.handleCpfClick()}
                >
                  <span style={{ marginRight: '10px', width: '150px', textAlign: 'right' }}>
                    {t("body_params.carbs_fat_protein")}
                  </span>
                </div>
              }
              open={cpfExpanded}
            >
              <ShowCpf />
            </Collapsible>
          </li>
          {uniqueBodyParams.length > 0 &&
            uniqueBodyParams.map((item, index) => (
              <li key={index}>
                <Collapsible
                  trigger={
                    <div
                      style={{
                        cursor: 'pointer',
                        display: 'flex',
                        flexDirection: 'column',
                        marginBottom: '10px',
                      }}
                      onClick={() => this.handleItemClick(index)}
                    >
                      <span style={{ marginRight: '10px', width: '150px', textAlign: 'right' }}>
                        {item.dict_body_params_name}: {item.value}
                      </span>
                    </div>
                  }
                >
                  <ShowDiagram param_name={item.dict_body_params_name} />
                </Collapsible>
              </li>
            ))}
        </ul>
      </div>
    );
  }
}

export default withTranslation("global")(ShowBodyParams);
