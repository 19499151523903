import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import ClientsParams from '../../../../services/bodyParams/';
import ClientsService from '../../../../services/clients';
import UpdateClientUser from './UpdateClientUser';
import { Client } from '../../../../types/types';

interface ClientDetailsProps {
  client: Client;
}

const ClientInformations: React.FC<ClientDetailsProps> = ({ client }) => {
  const { t } = useTranslation('global');
  const [clientData, setClientData] = useState<Client | null>(null);
  const [age, setAge] = useState<string | null>(null);
  const [gender, setGender] = useState<string | null>(null);

  useEffect(() => {
    if (client) {
      ClientsService.getClient(client.id)
        .then(response => {
          setClientData(response.data);
          if (response.data.userId !== 0) {
            ClientsParams.getAgeByUserId(response.data.userId)
              .then(res => {
                setAge(res.data.age);
              })
              .catch(error => {
                console.error('Error fetching age:', error);
              });

            ClientsParams.getGenderByUserId(response.data.userId)
              .then(res => {
                setGender(res.data.gender);
              })
              .catch(error => {
                console.error('Error fetching gender:', error);
              });
          }
        })
        .catch(error => {
          console.error('Error fetching client:', error);
        });
    }
  }, [client]);

  if (!clientData) {
    return <div>{t('clients.loading_client')}</div>;
  }

  return (
    <div>
      <p>{t('clients.client_name')}: {clientData.clientName}</p>
      {age && <p>{t('clients.age')}: {age}</p>}
      {gender && <p>{t('clients.gender')}: {gender}</p>}
      {/* Możesz dodać tutaj inne właściwości klienta, np. datę urodzenia */}
      <p>
        {t('clients.client_assigned_user')}: {clientData.userId === 0 ? t('clients.user_is_not_assigned') : clientData.userName}
      </p>
      {clientData.userId === 0 && <UpdateClientUser client={clientData} />}
    </div>
  );
};

export default ClientInformations;
